<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">廣宣來源</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 mr-1 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
          <!-- <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="showModal">
              產生廣宣網址
            </button>
          </div> -->
        </div>
        <!-- <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="(row, callback) => { row.Published = false; row.ViewCount = 0; callback(); }"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
          @modalResize="onGridModalResize"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-category="{ data }">
                <select-box
                  class="custom-vue-selector"
                  v-bind="selectorOptions"
                  v-model="data.SourceId"
                  @change="changeInput(data)"
                />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{option.label}}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- <vxe-modal width="70%" v-model="generateUrlVisible" title="產生廣宣網址" height="400" show-zoom="true">
      <GenerateUrlTable :visible="generateUrlVisible" />
    </vxe-modal> -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import GenerateUrlTable from "@/components/utm/GenerateUrlTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    GenerateUrlTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '廣宣來源',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '廣宣來源清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '廣宣來源清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'Source.Name', title: '類別名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Medium', title: '廣告活動媒介', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Campaign', title: '廣告活動名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Term', title: '付費關鍵字廣告字詞', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Content', title: '付費關鍵字廣告字詞', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      ],
      promises: {
        query: model ? (params) => {
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('utm/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('utm/query') : undefined,
        save: model ? (params) => model.dispatch('utm/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const changeInput = (data: any) => {
      if(data.SourceUrl && data.Id) {
        var params = data.SourceUrl?.includes('?') ? '&' : '?';
        //`${table.formData.Url}${params}medium=${utm.Medium}&campaign=${utm.Campaign}&term=${utm.Term}&content=${utm.Content}&sourceId=${utm.SourceId}&utmId=${utm.Id}`
        data.TargetUrl = `${data.SourceUrl}${params}medium=${data.Medium}&campaign=${data.Campaign}&term=${data.Term}&content=${data.Content}&sourceId=${data?.SourceId ?? ''}&utmId=${data?.Id}`
      } else {
        data.TargetUrl = null;
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 180,
      titleAlign: 'right',
      items: [
        {
          field: 'Medium',
          title: '廣告活動媒介',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' }, events: { change: (e: any) => changeInput(e.data) }, }
        },
        {
          field: 'Campaign',
          title: '廣告活動名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' }, events: { change: (e: any) => changeInput(e.data) }, }
        },
        {
          field: 'Term',
          title: '付費關鍵字廣告字詞',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' }, events: { change: (e: any) => changeInput(e.data) }, }
        },
        {
          field: 'Content',
          title: '廣告活動內容',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' }, events: { change: (e: any) => changeInput(e.data) }, }
        },
        {
          title: '廣宣來源',
          field: 'SourceId',
          span: 12,
          slots: { default: 'column-category' }
        },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },        
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'SourceUrl', title: '來源網址', span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, events: { change: (e: any) => changeInput(e.data) }, },
        },
        {
          field: 'TargetUrl',
          title: '參數網址',
          span: 24,
          itemRender: { name: '$textarea', props: { readonly: true, autosize: { minRows: 2, maxRows: 4 }, placeholder: '系統自動產生' } }
        },
      ],
      rules: {
        Medium: [{ required: true }],
        Campaign: [{ required: true }],
        Term: [{ required: true }],
        Content: [{ required: true }],
        SourceId: [{ required: true }],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: (value: any) => {          
          return model!.dispatch("utmCategory/find", value); // eslint-disable-line
        },
        query: (params) => {
          params.condition = new Condition().and("Published", Operator.Equal, true);
          params.sortings = [{ column: "Ordinal", order: 0 }, { column: "CreatedTime", order: 1 }];
          return model!.dispatch("utmCategory/query", params) 
        }// eslint-disable-line
      }
    };

    const generateUrlVisible = ref(false);

    const showModal = () => {
      generateUrlVisible.value = true;
    }

    return {
      grid,
      selectorOptions,
      gridOptions,
      formOptions,
      generateUrlVisible,
      showModal,
      changeInput
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit(row: any, callback: any) {
      callback();
    }
  }
})
</script>

<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}
.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}
</style>
