<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <div class="flex items-center mx-1 mb-2">
      廣宣網址產生設定
    </div>
    <vxe-form v-if="table.formData" class="" ref="form" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
    <!-- <vxe-form-item title="廣告活動媒介" field="Medium" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Medium" placeholder="請輸入文字(ex.Banner)" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="廣告活動名稱" field="Campaign" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Campaign" placeholder="請輸入文字(ex.ActivityPost)" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="付費關鍵字廣告字詞" field="Term" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Term" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="廣告活動內容" field="Content" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Content" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item> -->
      <vxe-form-item title="廣宣來源" field="UtmId" :item-render="{}" span="24">
        <template #default="{ data }">
          <select-box
            class="custom-vue-selector"
            v-bind="selectorOptions"
            v-model="data.UtmId"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item title="目標網址" field="Url" :item-render="{}" span="24">
        <template #default="{ data }">
          <vxe-input type="text" v-model="data.Url" placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="產生網址" field="Result" :item-render="{}" span="24">
        <template #default="{ data }">
         {{ data.Result }}
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item v-if="table?.data?.Url" title="※範例" :item-render="{}" span="24">
        <template #default="{ data }">
          <div>將參數放在完整的網址之後：{{ `https://mii.mirdc.org.tw${data.Url}` }}</div>
        </template>
      </vxe-form-item> -->
      <vxe-form-item class-name="text-left" :item-render="{}" span="24">
        <template #default>
          <vxe-button class="" type="submit" status="primary" content="確認">
          </vxe-button>
        </template>
      </vxe-form-item>
    </vxe-form>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue-demi';
import RecordTable from "@/components/coupon-set/RecordTable.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    RecordTable,
    SelectBox,
  },
  props: {
    visible: Boolean,
  },
  setup(props) {
    const model = CloudFun.current?.model
    const form = ref<any>({});
    const table = reactive({
      data: {} as any,
      isLoading: false,
      isEditing: false,
      formData: {
        UtmId: 0,
        // Medium: '',
        // Campaign: '',
        // Term: '',
        // Content: '',
        // SourceId: 0,
        Result: '',
        Url: '',
      } as any,
      selectedRow: null as any | null,
      async save() {
        try {
          if(table.formData.UtmId) {
            var utm = await model!.dispatch("utm/find", table.formData.UtmId);
            if(utm) {
              var params = table.formData?.Url?.includes('?') ? '&' : '?';
              table.formData.Result = `${table.formData.Url}${params}medium=${utm.Medium}&campaign=${utm.Campaign}&term=${utm.Term}&content=${utm.Content}&sourceId=${utm.SourceId}&utmId=${utm.Id}`
            }
          } else {
            CloudFun.send("error", {
            subject: "執行失敗",
            content: '查無廣宣資料',
          });
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        }
      },
    })

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Campaign",
      valueField: "Id",
      formatText: (row) => row.Source?.Name ? `${row.Source?.Name}-${row.Medium}-${row.Campaign}-${row.Term}-${row.Content}` : row.Medium,
      columns: [
        {
          field: "Source.Name",
          title: "來源類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Medium",
          title: "活動媒介",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Campaign",
          title: "活動名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Term",
          title: "關鍵字廣告字詞",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Content",
          title: "活動內容",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: (value: any) => {          
          return model!.dispatch("utm/find", value); // eslint-disable-line
        },
        query: (params) => {
          params.condition = new Condition().and("Published", Operator.Equal, true);
          params.sortings = [{ column: "Ordinal", order: 0 }, { column: "CreatedTime", order: 1 }];
          return model!.dispatch("utm/query", params) 
        }// eslint-disable-line
      }
    };

    watch(() => props.visible, (val) => {
      if(val) {
        table.formData = {
          UtmId: 0,
          // Medium: '',
          // Campaign: '',
          // Term: '',
          // Content: '',
          // SourceId: 0,
          Result: '',
          Url: '',
        } as any;
      }
    })

    return {
      form,
      model,
      table,
      selectorOptions,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
</script>

<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}
.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}
</style>
