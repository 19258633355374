
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid
  },
  props: {
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '發行紀錄',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      showFooter: true,
      columns: [
        { field: 'CreatedTime', title: '建立時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        { field: 'CouponSetId', title: '折扣碼設定識別碼', showOverflow: true, sortable: true, resizable: false },
        { field: 'CategoryId', title: '折扣類型識別碼', showOverflow: true, sortable: true, resizable: false },
        { field: 'SessionId', title: '活動場次資料識別碼', showOverflow: true, sortable: true, resizable: false },
        { field: 'StartDate', title: '開始日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd') },
        { field: 'EndDate', title: '結束日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd') },
        { field: 'Discount', title: '折扣', showOverflow: true, sortable: true, resizable: false },
        { field: 'Count', title: '發行數量', showOverflow: true, sortable: true, resizable: false },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "400", showFooter: true },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'CreatedTime',
          title: '建立時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', disabled: 'true' } }
        },
        {
          field: 'CouponSetId',
          title: '折扣碼設定識別碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'CategoryId',
          title: '折扣類型識別碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'SessionId',
          title: '活動場次資料識別碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'StartDate',
          title: '開始日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', disabled: 'true' } }
        },
        {
          field: 'EndDate',
          title: '結束日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', disabled: 'true' } }
        },
        {
          field: 'Discount',
          title: '折扣',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'Count',
          title: '發行數量',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
      ],
      rules:{
      }
    }

    const close = () => {
      grid.value.isModalPopup = false;
    }

    return {
      grid,
      gridOptions,
      formOptions,
      close,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
