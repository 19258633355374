
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import GenerateUrlTable from "@/components/utm/GenerateUrlTable.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    GenerateUrlTable,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '廣宣來源',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '廣宣來源清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '廣宣來源清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'Source.Name', title: '類別名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Medium', title: '廣告活動媒介', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Campaign', title: '廣告活動名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Term', title: '付費關鍵字廣告字詞', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Content', title: '付費關鍵字廣告字詞', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      ],
      promises: {
        query: model ? (params) => {
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('utm/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('utm/query') : undefined,
        save: model ? (params) => model.dispatch('utm/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const changeInput = (data: any) => {
      if(data.SourceUrl && data.Id) {
        var params = data.SourceUrl?.includes('?') ? '&' : '?';
        //`${table.formData.Url}${params}medium=${utm.Medium}&campaign=${utm.Campaign}&term=${utm.Term}&content=${utm.Content}&sourceId=${utm.SourceId}&utmId=${utm.Id}`
        data.TargetUrl = `${data.SourceUrl}${params}medium=${data.Medium}&campaign=${data.Campaign}&term=${data.Term}&content=${data.Content}&sourceId=${data?.SourceId ?? ''}&utmId=${data?.Id}`
      } else {
        data.TargetUrl = null;
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 180,
      titleAlign: 'right',
      items: [
        {
          field: 'Medium',
          title: '廣告活動媒介',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' }, events: { change: (e: any) => changeInput(e.data) }, }
        },
        {
          field: 'Campaign',
          title: '廣告活動名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' }, events: { change: (e: any) => changeInput(e.data) }, }
        },
        {
          field: 'Term',
          title: '付費關鍵字廣告字詞',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' }, events: { change: (e: any) => changeInput(e.data) }, }
        },
        {
          field: 'Content',
          title: '廣告活動內容',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' }, events: { change: (e: any) => changeInput(e.data) }, }
        },
        {
          title: '廣宣來源',
          field: 'SourceId',
          span: 12,
          slots: { default: 'column-category' }
        },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },        
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'SourceUrl', title: '來源網址', span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, events: { change: (e: any) => changeInput(e.data) }, },
        },
        {
          field: 'TargetUrl',
          title: '參數網址',
          span: 24,
          itemRender: { name: '$textarea', props: { readonly: true, autosize: { minRows: 2, maxRows: 4 }, placeholder: '系統自動產生' } }
        },
      ],
      rules: {
        Medium: [{ required: true }],
        Campaign: [{ required: true }],
        Term: [{ required: true }],
        Content: [{ required: true }],
        SourceId: [{ required: true }],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: (value: any) => {          
          return model!.dispatch("utmCategory/find", value); // eslint-disable-line
        },
        query: (params) => {
          params.condition = new Condition().and("Published", Operator.Equal, true);
          params.sortings = [{ column: "Ordinal", order: 0 }, { column: "CreatedTime", order: 1 }];
          return model!.dispatch("utmCategory/query", params) 
        }// eslint-disable-line
      }
    };

    const generateUrlVisible = ref(false);

    const showModal = () => {
      generateUrlVisible.value = true;
    }

    return {
      grid,
      selectorOptions,
      gridOptions,
      formOptions,
      generateUrlVisible,
      showModal,
      changeInput
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async onGridEdit(row: any, callback: any) {
      callback();
    }
  }
})
