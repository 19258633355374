
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue-demi';
import RecordTable from "@/components/coupon-set/RecordTable.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    RecordTable,
    SelectBox,
  },
  props: {
    visible: Boolean,
  },
  setup(props) {
    const model = CloudFun.current?.model
    const form = ref<any>({});
    const table = reactive({
      data: {} as any,
      isLoading: false,
      isEditing: false,
      formData: {
        UtmId: 0,
        // Medium: '',
        // Campaign: '',
        // Term: '',
        // Content: '',
        // SourceId: 0,
        Result: '',
        Url: '',
      } as any,
      selectedRow: null as any | null,
      async save() {
        try {
          if(table.formData.UtmId) {
            var utm = await model!.dispatch("utm/find", table.formData.UtmId);
            if(utm) {
              var params = table.formData?.Url?.includes('?') ? '&' : '?';
              table.formData.Result = `${table.formData.Url}${params}medium=${utm.Medium}&campaign=${utm.Campaign}&term=${utm.Term}&content=${utm.Content}&sourceId=${utm.SourceId}&utmId=${utm.Id}`
            }
          } else {
            CloudFun.send("error", {
            subject: "執行失敗",
            content: '查無廣宣資料',
          });
          }
        } catch (error: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        }
      },
    })

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Campaign",
      valueField: "Id",
      formatText: (row) => row.Source?.Name ? `${row.Source?.Name}-${row.Medium}-${row.Campaign}-${row.Term}-${row.Content}` : row.Medium,
      columns: [
        {
          field: "Source.Name",
          title: "來源類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Medium",
          title: "活動媒介",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Campaign",
          title: "活動名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Term",
          title: "關鍵字廣告字詞",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Content",
          title: "活動內容",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: (value: any) => {          
          return model!.dispatch("utm/find", value); // eslint-disable-line
        },
        query: (params) => {
          params.condition = new Condition().and("Published", Operator.Equal, true);
          params.sortings = [{ column: "Ordinal", order: 0 }, { column: "CreatedTime", order: 1 }];
          return model!.dispatch("utm/query", params) 
        }// eslint-disable-line
      }
    };

    watch(() => props.visible, (val) => {
      if(val) {
        table.formData = {
          UtmId: 0,
          // Medium: '',
          // Campaign: '',
          // Term: '',
          // Content: '',
          // SourceId: 0,
          Result: '',
          Url: '',
        } as any;
      }
    })

    return {
      form,
      model,
      table,
      selectorOptions,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
